
.col_analyzing_tool_btn {
  position: relative;
  .v-btn {
    position: absolute;
    right: 30px;
  }
}
.analyzing_tool_btn {
  margin: 0 50px;
  display: flex;
  position: relative;
  .v-btn {
    position: absolute;
    right: 0;
  }
}
