
.technologyRoadmap {
  background-image: url(../../../assets/bg.png);
  background-size: 100% 100%;
  width: 980px;
  height: 514px;
  position: relative;
  font-size: 16px;
}
.roadmap_bg {
  background: linear-gradient(#cfe1c7, #a7b6a1);
  width: auto;
  position: absolute;
  box-shadow: 0 0 2px #ddd;
  padding: 5px;
  border: 1px solid #a7b6a1;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}
.roadmap_title1 {
  position: absolute;
  top: 10px;
  left: 20px;
}
.roadmap_title2 {
  position: absolute;
  top: 150px;
  left: 20px;
}
.roadmap_title3 {
  position: absolute;
  top: 345px;
  left: 20px;
}
.roadmap_1 {
  top: 48px;
  left: 20px;
}
.roadmap_2 {
  top: 48px;
  left: 118px;
}
.roadmap_3 {
  top: 48px;
  left: 210px;
  min-width: 85px;
}
.roadmap_4 {
  top: 48px;
  left: 318px;
  min-width: 132px;
}
.roadmap_5 {
  top: 48px;
  left: 480px;
}
.roadmap_6 {
  top: 10px;
  left: 640px;
  min-width: 130px;
}
.roadmap_7 {
  top: 88px;
  left: 640px;
  min-width: 130px;
}
.roadmap_8 {
  top: 48px;
  left: 810px;
  min-width: 130px;
}
.roadmap_9 {
  top: 206px;
  left: 43px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_10 {
  top: 236px;
  left: 43px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_11 {
  top: 266px;
  left: 43px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_12 {
  top: 152px;
  left: 390px;
  min-width: 145px;
}
.roadmap_13 {
  top: 206px;
  left: 640px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_14 {
  top: 236px;
  left: 640px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_15 {
  top: 266px;
  left: 640px;
  padding: 0 4px;
  min-width: 240px;
}
.roadmap_16 {
  top: 352px;
  left: 340px;
  padding: 0 4px;
  min-width: 248px;
}
.roadmap_17 {
  top: 390px;
  left: 340px;
  padding: 0 4px;
  min-width: 248px;
}
.roadmap_18 {
  top: 428px;
  left: 340px;
  padding: 0 4px;
  min-width: 248px;
}
.roadmap_19 {
  top: 466px;
  left: 340px;
  padding: 0 4px;
  min-width: 248px;
}
