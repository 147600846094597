
.left_triz_display {
  .v-stepper__label {
    position: relative;
    display: flex;
  }
}
.v-stepper__step__step {
  line-height: 36px;
  width: 36px;
  height: 36px;
  min-width: 36px;
}
.children_Content {
  .v-stepper--vertical {
    padding-bottom: 8px;
  }
}
.left_triz_displayChildren {
  .v-stepper__label {
    position: relative;
    display: flex;
  }
  .v-stepper__step__step {
    width: 36px;
    height: 36px;
    line-height: 36px;
    min-width: 36px;
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
  }
}
.delete_tool {
  width: 20px !important;
  min-width: 20px !important;
  border: none;
  position: absolute;
  right: 0;
  border-radius: 20px;
  margin-top: -5px !important;
  .v-icon--left {
    margin: 0 !important;
  }
}
.bj_left_min_height {
  min-height: 100%;
}
.tab_topStop {
  position: sticky;
  top: 0;
  z-index: 999;
}
.bj_right_app .v-navigation-drawer__content::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.bj_left_app .v-navigation-drawer__content::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.gztitle {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
